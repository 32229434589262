import React, { useState } from 'react';
import axios from 'axios';

import { Card, Input, Button, Typography, Modal, Row, Col } from 'antd';

import './App.css';
import logo from './logo.png';

const { Title, Text } = Typography;

function App() {

    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);

    const [modalMessage, setModalMessage] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = (message) => {
        setModalMessage(message);
        setIsModalVisible(true);
    };
    
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`https://towerquest.firerock.live/api/da/${username}`);
            showModal(response.data.message);
        } catch (error) {
            showModal('Error deleting account.');
        } finally {
            setLoading(false);
            setUsername('');
        }
    };

    return (
        <div className="app-container">
            <Row align="middle" className="header">
                <Col>
                    <img src={logo} alt="App Logo" className="logo" />
                </Col>
                <Col>
                  <Row>
                    <span className="app-name">Tower Quest: Idle Defense</span>
                  </Row>
                  <Row>
                      <span className="studio-name">Fire Rock Studio</span>
                  </Row>
                </Col>
            </Row>
            <Card className="delete-account-card">
                <Title level={2} className="title">
                    Delete Account
                </Title>
                <Text type="secondary" className="description">
                After submitting a request to delete your account, your account and all associated data will be permanently deleted within 30 working days.
                During this period, you may cancel the deletion request by contacting support.
                </Text>
                <Text type="secondary" className="description">
                Once the process is complete, your account cannot be recovered. Please ensure you have backed up any important data before proceeding.
                </Text>
                <form onSubmit={handleSubmit} className="form">
                    <Input
                        placeholder="Enter your account name"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        className="input"
                    />
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        className="submit-button"
                    >
                        Delete Account
                    </Button>
                </form>
            </Card>
            <Modal
                title="Notice"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="ok" type="primary" onClick={handleOk}>
                      I understand
                    </Button>,
                ]}
            >
                <p>{modalMessage}</p>
        </Modal>
        </div>
    );
}

export default App;